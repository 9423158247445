@import url("https://fonts.googleapis.com/css2?family=Red+Rose:wght@500&display=swap");

* {
  box-sizing: border-box;
  font-family: "Red Rose", cursive;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
}
